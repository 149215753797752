<template>
  <b-overlay :show="loadingIndicator" rounded="sm" variant="dark">
    <b-container class="b-card-forms">
      <b-row class="vh-100" align-h="center">
        <b-col align-self="center" cols="12" sm="9" lg="5">
          <b-card v-if="!showPasswordResetError && !showPasswordResetSuccess">
            <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
            <div v-if="!passwordResetCodeSend">
              <b-alert
                variant="danger"
                :show="!!errorLoginCode"
                v-html="formErrorMsg"
              ></b-alert>
              <h4 class="mb-3"> {{ $t('login.resetPassword') }}</h4>
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group id="email-group">
                  <b-form-input
                    id="email-input"
                    name="email-input"
                    type="email"
                    :disabled="inputFieldDisabled"
                    :placeholder="$t('login.placeholderEmail')"
                    v-model.trim="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-live-feedback">
                    {{ $t('login.invalidEmail') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  class="p-3"
                  block
                  type="submit"
                  variant="success">
                  {{ $t('passwordReset.button') }}
                </b-button>
              </b-form>
            </div>
            <div v-else>
              <b-form @submit.stop.prevent="passwordReset">
                <b-form-group id="reset-password-group">
                  <b-form-input
                    id="email-input-reset"
                    name="email-input-reset"
                    type="email"
                    :disabled="inputFieldDisabled"
                    :placeholder="$t('login.placeholderEmail')"
                    v-model.trim="$v.formReset.email.$model"
                    :state="validateStateReset('email')"
                    aria-describedby="email-live-feedback-reset"
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-live-feedback-reset">
                    {{ $t('login.invalidEmail') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="password-group" class="position-relative">
                  <b-form-input
                    id="password-input"
                    name="password-input"
                    :placeholder="$t('passwordReset.newPasswordLabel')"
                    :type="showPassword ? 'text' : 'password'"
                    v-model.trim="$v.formReset.password.$model"
                    :state="validateStateReset('password')"
                    aria-describedby="password-live-feedback"
                  ></b-form-input>
                  <b-icon
                    v-if="!showPassword"
                    icon="eye-slash"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(true)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="eye"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(false)"
                  ></b-icon>
                  <b-form-invalid-feedback id="password-live-feedback">
                    <div class="error" v-if="!$v.formReset.password.required">
                      {{ $t('register.requiredPassword') }}
                    </div>
                    <div class="error" v-if="!$v.formReset.password.minLength">
                      {{
                        $t(
                          'register.passwordMinLength',
                          { pwLength: $v.formReset.password.$params.minLength.min },
                        )
                      }}
                    </div>
                    <div class="error" v-if="!$v.formReset.password.letterValidation">
                      {{ $t('register.letterRequired') }}
                    </div>
                    <div class="error" v-if="!$v.formReset.password.numberValidation">
                      {{ $t('register.numberRequired') }}
                    </div>
                    <div class="error" v-if="!$v.formReset.password.specialCharValidation">
                      {{ $t('register.specialCharRequired') }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="verification-code">
                  <b-form-input
                    id="verification-code-input"
                    name="verification-code-input"
                    type="number"
                    :placeholder="$t('passwordReset.placeholderVerificationCode')"
                    v-model.trim="$v.formReset.verificationCode.$model"
                  ></b-form-input>
                </b-form-group>
                <b-button
                  class="p-3"
                  block
                  type="submit"
                  variant="success">
                  {{ $t('passwordReset.resetButton') }}
                </b-button>
              </b-form>
            </div>
          </b-card>
          <password-reset-success-card v-if="showPasswordResetSuccess" />
          <password-reset-error-card
            v-if="showPasswordResetError"
            @reset-password-component="resetComponent"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import Auth from '@aws-amplify/auth';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import { validationMixin } from 'vuelidate';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';
import { required, email, minLength } from 'vuelidate/lib/validators';

const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const specialCharValidation = (value) => specialChars.test(value);
const numberValidation = (value) => /\d/.test(value);
const letterValidation = (value) => /[a-z]/.test(value);

export default {
  name: 'PasswordReset',
  components: {
    PasswordResetSuccessCard: () => import('@/components/messages/PasswordResetSuccessCard.vue'),
    PasswordResetErrorCard: () => import('@/components/messages/PasswordResetErrorCard.vue'),
  },
  mixins: [validationMixin, SetLanguageToBrowserLanguage],
  data: () => ({
    form: {
      email: null,
    },
    formReset: {
      email: null,
      password: null,
      verificationCode: null,
    },
    errorLoginCode: null,
    loadingIndicator: true,
    passwordResetCodeSend: false,
    sendPasswordResetEmail: false,
    inputFieldDisabled: false,
    showPassword: false,
    showPasswordResetSuccess: false,
    showPasswordResetError: false,
  }),
  computed: {
    formErrorMsg() {
      switch (this.errorLoginCode) {
        case 'LimitExceededException':
          return this.$t('passwordReset.limitExceededException');
        case 'auth/user-disabled':
          return this.$t('login.error.userDisabled');
        case 'auth/wrong-password':
          return this.$t('login.error.wrongPassword');
        default:
          return this.errorLoginCode;
      }
    },
  },
  async created() {
    this.setLanguageToBrowserLanguage();
    try {
      const userInfo = await Auth.currentUserInfo();
      // display the email in the input field and disable the input field if user is logged in
      if (userInfo && Object.keys(userInfo).length !== 0) {
        this.inputFieldDisabled = true;
        this.$v.form.email.$model = userInfo?.attributes?.email;
      }
    } catch (error) {
      console.error(error);
    }
    await this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: '',
      },
    });
    this.loadingIndicator = false;
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
    formReset: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        specialCharValidation,
        letterValidation,
        numberValidation,
      },
      verificationCode: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateReset(name) {
      const { $dirty, $error } = this.$v.formReset[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.errorLoginCode = null;
      this.loadingIndicator = true;
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loadingIndicator = false;
        return;
      }
      try {
        const emailOfUser = this.form.email.toLowerCase().trim();
        await Auth.forgotPassword(emailOfUser);
        this.passwordResetCodeSend = true;
        this.formReset.email = emailOfUser;
      } catch (err) {
        if (err.code === 'LimitExceededException') {
          this.errorLoginCode = err.code;
        }
        console.log(err.code);
        console.error(err);
      }
      this.loadingIndicator = false;
    },
    async passwordReset() {
      this.loadingIndicator = true;
      try {
        await Auth.forgotPasswordSubmit(
          this.formReset.email,
          this.formReset.verificationCode,
          this.formReset.password,
        );
        this.showPasswordResetSuccess = true;
      } catch (error) {
        console.log('password reset failed', error);
        this.showPasswordResetError = true;
      }
      this.loadingIndicator = false;
    },
    showLoader(value) {
      this.loadingIndicator = !!(value);
    },
    resetComponent() {
      this.showPasswordResetSuccess = false;
      this.showPasswordResetError = false;
      this.form.email = null;
    },
    togglePasswordVisibility(value) {
      this.showPassword = !!value;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 35px;
}

small {
  display: block;
}

.password-eye {
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.is-valid ~ .password-eye,
.is-invalid ~ .password-eye {
  right: 35px;
}
</style>
