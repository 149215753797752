var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loadingIndicator, rounded: "sm", variant: "dark" } },
    [
      _c(
        "b-container",
        { staticClass: "b-card-forms" },
        [
          _c(
            "b-row",
            { staticClass: "vh-100", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "align-self": "center",
                    cols: "12",
                    sm: "9",
                    lg: "5"
                  }
                },
                [
                  !_vm.showPasswordResetError && !_vm.showPasswordResetSuccess
                    ? _c("b-card", [
                        _c("img", {
                          staticClass: "webmag-logo-big",
                          attrs: {
                            src: require("@/assets/logo-webmag-big.png"),
                            alt: "webmag logo"
                          }
                        }),
                        !_vm.passwordResetCodeSend
                          ? _c(
                              "div",
                              [
                                _c("b-alert", {
                                  attrs: {
                                    variant: "danger",
                                    show: !!_vm.errorLoginCode
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formErrorMsg)
                                  }
                                }),
                                _c("h4", { staticClass: "mb-3" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.resetPassword"))
                                  )
                                ]),
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.onSubmit.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "email-group" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "email-input",
                                            name: "email-input",
                                            type: "email",
                                            disabled: _vm.inputFieldDisabled,
                                            placeholder: _vm.$t(
                                              "login.placeholderEmail"
                                            ),
                                            state: _vm.validateState("email"),
                                            "aria-describedby":
                                              "email-live-feedback"
                                          },
                                          model: {
                                            value: _vm.$v.form.email.$model,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$v.form.email,
                                                "$model",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "$v.form.email.$model"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: { id: "email-live-feedback" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("login.invalidEmail")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "p-3",
                                        attrs: {
                                          block: "",
                                          type: "submit",
                                          variant: "success"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("passwordReset.button")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.passwordReset.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "reset-password-group" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "email-input-reset",
                                            name: "email-input-reset",
                                            type: "email",
                                            disabled: _vm.inputFieldDisabled,
                                            placeholder: _vm.$t(
                                              "login.placeholderEmail"
                                            ),
                                            state: _vm.validateStateReset(
                                              "email"
                                            ),
                                            "aria-describedby":
                                              "email-live-feedback-reset"
                                          },
                                          model: {
                                            value:
                                              _vm.$v.formReset.email.$model,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$v.formReset.email,
                                                "$model",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "$v.formReset.email.$model"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "email-live-feedback-reset"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("login.invalidEmail")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "position-relative",
                                        attrs: { id: "password-group" }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "password-input",
                                            name: "password-input",
                                            placeholder: _vm.$t(
                                              "passwordReset.newPasswordLabel"
                                            ),
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            state: _vm.validateStateReset(
                                              "password"
                                            ),
                                            "aria-describedby":
                                              "password-live-feedback"
                                          },
                                          model: {
                                            value:
                                              _vm.$v.formReset.password.$model,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$v.formReset.password,
                                                "$model",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "$v.formReset.password.$model"
                                          }
                                        }),
                                        !_vm.showPassword
                                          ? _c("b-icon", {
                                              staticClass:
                                                "position-absolute password-eye",
                                              attrs: {
                                                icon: "eye-slash",
                                                "aria-hidden": "true",
                                                scale: "1"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.togglePasswordVisibility(
                                                    true
                                                  )
                                                }
                                              }
                                            })
                                          : _c("b-icon", {
                                              staticClass:
                                                "position-absolute password-eye",
                                              attrs: {
                                                icon: "eye",
                                                "aria-hidden": "true",
                                                scale: "1"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.togglePasswordVisibility(
                                                    false
                                                  )
                                                }
                                              }
                                            }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "password-live-feedback"
                                            }
                                          },
                                          [
                                            !_vm.$v.formReset.password.required
                                              ? _c(
                                                  "div",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "register.requiredPassword"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.formReset.password.minLength
                                              ? _c(
                                                  "div",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "register.passwordMinLength",
                                                            {
                                                              pwLength:
                                                                _vm.$v.formReset
                                                                  .password
                                                                  .$params
                                                                  .minLength.min
                                                            }
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.formReset.password
                                              .letterValidation
                                              ? _c(
                                                  "div",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "register.letterRequired"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.formReset.password
                                              .numberValidation
                                              ? _c(
                                                  "div",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "register.numberRequired"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.formReset.password
                                              .specialCharValidation
                                              ? _c(
                                                  "div",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "register.specialCharRequired"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "verification-code" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "verification-code-input",
                                            name: "verification-code-input",
                                            type: "number",
                                            placeholder: _vm.$t(
                                              "passwordReset.placeholderVerificationCode"
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.$v.formReset.verificationCode
                                                .$model,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$v.formReset
                                                  .verificationCode,
                                                "$model",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "$v.formReset.verificationCode.$model"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "p-3",
                                        attrs: {
                                          block: "",
                                          type: "submit",
                                          variant: "success"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "passwordReset.resetButton"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ])
                    : _vm._e(),
                  _vm.showPasswordResetSuccess
                    ? _c("password-reset-success-card")
                    : _vm._e(),
                  _vm.showPasswordResetError
                    ? _c("password-reset-error-card", {
                        on: { "reset-password-component": _vm.resetComponent }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }