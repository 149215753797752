var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { show: _vm.$apollo.loading, rounded: "sm", variant: "dark" } },
    [
      _vm.currentItemObject &&
      _vm.currentItemObject.incompatibleItem &&
      _vm.userObject &&
      !_vm.userObject.isSysAdmin
        ? _c("modal-incompatible-item", {
            attrs: {
              "item-name": _vm.currentItemObject.parentProjectName,
              "modal-for-detail-page": true
            }
          })
        : !_vm.$apollo.loading &&
          _vm.currentItemObject &&
          _vm.$hasPrivileges(
            _vm.$privileges("CAN_EDIT_PAGE_SETTINGS"),
            _vm.userObject.isSysAdmin,
            _vm.currentItemObject.privilegesForItem
          )
        ? _c(
            "b-tabs",
            {
              attrs: {
                vertical: "",
                "nav-wrapper-class": "col-3",
                "nav-class": "mt-5"
              },
              scopedSlots: _vm._u([
                {
                  key: "tabs-start",
                  fn: function() {
                    return [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("settings.settings")))])
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            [
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_PAGE_SETTINGS_PAGE"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setUrl("")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.page.tabs.pageName")
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        213305929
                      )
                    },
                    [
                      _vm.userObject && _vm.pageSettings
                        ? _c("page-settings-page-description", {
                            staticClass: "mt-5",
                            attrs: {
                              "page-data": _vm.pageData,
                              "page-tags": _vm.pageTags,
                              "sibling-slugs": _vm.allChildSlugsOfParentFolder,
                              "all-tags": _vm.allTagsOfGroup,
                              "has-parent-folder": _vm.hasParentFolder,
                              "is-sys-admin": _vm.userObject.isSysAdmin
                            },
                            on: {
                              updatePageSettings:
                                _vm.updatePageDescriptionSettings
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_PAGE_SETTINGS_SEO"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.page.tabs.seoHash"),
                      on: {
                        click: function($event) {
                          _vm.setUrl(_vm.$t("settings.page.tabs.seoHash"))
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.page.tabs.seoName")
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        264910403
                      )
                    },
                    [
                      _c("page-settings-seo", {
                        staticClass: "mt-5",
                        attrs: { "seo-data": _vm.seoData },
                        on: { updateSOSettings: _vm.updateSeoSettings }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_PAGE_SETTINGS_SEO"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.page.tabs.contextName")
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2064538117
                      )
                    },
                    [
                      _vm.personalizationData
                        ? _c("page-settings-context", {
                            staticClass: "mt-5",
                            attrs: {
                              "context-data": _vm.contextData,
                              "personalization-values":
                                _vm.personalizationValues
                            },
                            on: {
                              "context-create": _vm.createContext,
                              "context-update": _vm.updateContext,
                              "context-element-update":
                                _vm.updateContextElement,
                              "context-copy": _vm.copyContext,
                              "remove-element": _vm.removeElement,
                              "remove-context": _vm.removeContext
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("modal-leave-page", { ref: "leaveModalWindow" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }